import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import VenueListTable from "./Components/VenueListTable";
import {WithServiceState} from "store-fetch-wrappers";
import {getAllVenues} from "../../../store/venueList/actions/VenueListActions";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
const ServiceWrapper = WithServiceState(VenueListTable);

const VenueList = () => {
    const dispatch = useDispatch();
    const venueListStore = useSelector((state: RootStore) => state.venueList);
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getAllVenues());
    }, []);
    return (
        <React.Fragment>
            <TopBar titleText="Venues" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    {...venueListStore}
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                />
            </div>
        </React.Fragment>
    );
};

export default VenueList;
