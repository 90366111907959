import React, {useEffect} from "react";
import {Category} from "../../../../api/rm";
import {useDispatch} from "react-redux";
import {toArray} from "../../../../utils/sortingUtils";
import {nullifyCategoryListStore} from "../../../../store/categoryList/actions/CategoryListActions";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import TableItemAction from "../../../Table/TableItemAction";
import {IconType} from "../../../Icon/Icon";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const CategoryListTable = (props: Category[]) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return function () {
            dispatch(nullifyCategoryListStore());
        };
    }, []);

    const editCategory = (row: TableRow) => {
        const path = `${routeNames.categoryEdit.path}/${row.actions}`;
        history.push(path);
    };

    const addCategory = () => {
        history.push(routeNames.categoryAdd.path);
    };

    return (
        <div className="shens">
            <FormActionContainer>
                <div className="col pr-0 pl-0 d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Category"}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                        onClick={addCategory}
                    />
                </div>
            </FormActionContainer>
            <PulseTable
                items={toTableRow(toArray(props))}
                headers={{
                    name: "Name",
                    actions: "Actions"
                }}
                noItemsSection={<NoItemsMessage message={"There are no categories that exist"} />}
                customRenderers={{
                    actions: (item: TableRow) => {
                        return (
                            <React.Fragment>
                                <TableItemAction
                                    tooltipText={`Edit Category (${item.name})`}
                                    icon={IconType.Edit}
                                    onClick={() => editCategory(item)}
                                />
                            </React.Fragment>
                        );
                    }
                }}
            />
        </div>
    );
};

export default CategoryListTable;

interface TableRow {
    name: string;
    actions: number;
}

function toTableRow(props: Category[]): TableRow[] {
    return props.map((item) => {
        return {
            name: item.name,
            actions: item.id
        };
    });
}
