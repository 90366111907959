import React from "react";
import {
    getRiskCategoryFromString,
    getTimeScaleForReviewFromString,
    getUpdatedReviewDateFromTimeScale,
    ReviewTimeScale,
    RiskAssessmentMatrixRow,
    RiskCategory
} from "../../../../../../../store/audit/helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import {useDispatch} from "react-redux";
import {
    removeRowFromRiskAssessmentMatrix,
    setRiskAssessmentMatrixRow
} from "../../../../../../../store/audit/actions/AuditActions";
import {formatUnixToDDMMYYYY} from "../../../../../../../utils/momentUtils";
import GenericTypeDropdown from "../../../../../../Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../../../../../../Dropdown/Helpers/dropdownUtils";
import StaffDropdown from "../../../../../../Dropdown/StaffDropdown";
import {StaffAccessLevel} from "../../../../../../../api/staff";
import PulseDatePicker from "../../../../../../DatePicker/PulseDatePicker";
import moment from "moment";
import MatrixRatingTable from "./MatrixRatingTable";
import Tooltip from "../../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import {useIsHistoricAudit} from "../../../../../../Hooks/useIsHistoricAudit";
import {Modal, useModal} from "pulse-modal";
import {DebounceInput} from "react-debounce-input";

const RiskAssessmentTableRow = (props: RiskAssessmentMatrixRow) => {
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const isHistoric = useIsHistoricAudit();

    const deleteRiskAssessmentRow = () => {
        dispatch(removeRowFromRiskAssessmentMatrix(props.id));
    };

    return (
        <React.Fragment>
            <tr className="risk-assessment-row">
                <td align="center" className="mc-table-row-item min-width-500px p-3">
                    <DebounceInput
                        className={"input-fields text-area-inputs"}
                        debounceTimeout={300}
                        element={"textarea"}
                        value={props.riskDescription}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setRiskAssessmentMatrixRow({...props, riskDescription: value})
                            );
                        }}
                        placeholder={"Enter Risk Description..."}
                        disabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-500px p-3">
                    <DebounceInput
                        className={"input-fields text-area-inputs"}
                        debounceTimeout={300}
                        element={"textarea"}
                        value={props.causeOfIdentifiedRisk}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    causeOfIdentifiedRisk: value
                                })
                            );
                        }}
                        disabled={isHistoric}
                        placeholder={"Enter Cause/Source of Identified Risk..."}
                    />
                </td>
                <td className="mc-table-row-item min-width-200px p-3">
                    {formatUnixToDDMMYYYY(props.dateCreated)}
                </td>
                <td align="center" className="mc-table-row-item min-width-200px p-3">
                    <PulseDatePicker
                        startDate={moment.unix(props.lastReviewed)}
                        dateFormat={"dd/MM/yyyy"}
                        onDateChanged={(newDate) => {
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    lastReviewed: newDate.unix(),
                                    reviewDate: getUpdatedReviewDateFromTimeScale(
                                        props.reviewTimeScale,
                                        newDate.unix()
                                    )
                                })
                            );
                        }}
                        disabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-350px p-3">
                    <GenericTypeDropdown
                        enumOptions={ReviewTimeScale}
                        splitByCapitalLetter={true}
                        getOptionsFrom={OptionsFromType.Value}
                        searchWithDecapitalisedFirstLetter={false}
                        clearable={false}
                        searchable={false}
                        changeOption={(item: string | number) => {
                            const reviewTimeScale = getTimeScaleForReviewFromString(
                                item.toString()
                            );
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    reviewTimeScale,
                                    reviewDate: getUpdatedReviewDateFromTimeScale(
                                        reviewTimeScale,
                                        props.lastReviewed
                                    )
                                })
                            );
                        }}
                        id={props.reviewTimeScale}
                        disabled={isHistoric}
                    />
                </td>

                <td className="mc-table-row-item min-width-200px p-3">
                    {formatUnixToDDMMYYYY(props.reviewDate)}
                </td>
                <td align="center" className="mc-table-row-item min-width-350px p-3">
                    <GenericTypeDropdown
                        enumOptions={RiskCategory}
                        splitByCapitalLetter={true}
                        getOptionsFrom={OptionsFromType.Value}
                        searchWithDecapitalisedFirstLetter={false}
                        clearable={false}
                        searchable={false}
                        changeOption={(item: string | number) => {
                            const riskCategory = getRiskCategoryFromString(item.toString());
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    riskCategory
                                })
                            );
                        }}
                        id={props.riskCategory}
                        disabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-350px p-3">
                    <StaffDropdown
                        disabled={props.riskAssessmentRowVersion > 0 || isHistoric}
                        searchable={true}
                        id={props.riskOwner.staffId}
                        clearable={props.riskAssessmentRowVersion === 0}
                        accessLevelFilter={StaffAccessLevel.SystemAdministrator}
                        changeOption={(riskOwner) => {
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    riskOwner
                                })
                            );
                        }}
                    />
                </td>
                <td align="center" className="mc-table-row-item-item min-width-350px p-3">
                    <MatrixRatingTable
                        rating={props.initialRating}
                        onChange={(initialRating) => {
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    initialRating,
                                    currentRating: {
                                        ...props.currentRating,
                                        severityLikelihoodMatrix:
                                            initialRating.severityLikelihoodMatrix
                                    },
                                    targetRating: {
                                        ...props.targetRating,
                                        severityLikelihoodMatrix:
                                            initialRating.severityLikelihoodMatrix
                                    }
                                })
                            );
                        }}
                        severityDisabled={props.riskAssessmentRowVersion > 0 || isHistoric}
                        likelihoodDisabled={props.riskAssessmentRowVersion > 0 || isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-500px p-3">
                    <DebounceInput
                        className={"input-fields text-area-inputs"}
                        debounceTimeout={300}
                        element={"textarea"}
                        value={props.initialRating.details}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    initialRating: {...props.initialRating, details: value}
                                })
                            );
                        }}
                        placeholder={"Enter existing controls..."}
                        disabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item-item min-width-350px p-3">
                    <MatrixRatingTable
                        rating={props.currentRating}
                        onChange={(currentRating) => {
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    currentRating
                                })
                            );
                        }}
                        severityDisabled={true}
                        likelihoodDisabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-500px p-3">
                    <DebounceInput
                        className={"input-fields text-area-inputs"}
                        debounceTimeout={300}
                        element={"textarea"}
                        value={props.currentRating.details}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    currentRating: {...props.currentRating, details: value}
                                })
                            );
                        }}
                        placeholder={"Enter further actions..."}
                        disabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item-item min-width-350px p-3">
                    <MatrixRatingTable
                        rating={props.targetRating}
                        onChange={(targetRating) => {
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    targetRating
                                })
                            );
                        }}
                        severityDisabled={true}
                        likelihoodDisabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-500px p-3">
                    <DebounceInput
                        className={"input-fields text-area-inputs"}
                        debounceTimeout={300}
                        element={"textarea"}
                        value={props.targetRating.details}
                        onChange={(event) => {
                            const {value} = event.target;
                            dispatch(
                                setRiskAssessmentMatrixRow({
                                    ...props,
                                    targetRating: {...props.targetRating, details: value}
                                })
                            );
                        }}
                        placeholder={"Enter additional comments..."}
                        disabled={isHistoric}
                    />
                </td>
                <td align="center" className="mc-table-row-item min-width-350px p-3">
                    <div className="ml-0 row">
                        {!isHistoric && (
                            <div className="icon-tooltip-wrapper-md mr-3">
                                <Tooltip
                                    tooltipText={"Delete Risk Assessment Row"}
                                    size={"md"}
                                    place={"right"}
                                >
                                    <Icon
                                        rootElement={"Div"}
                                        icon={IconType.Bin}
                                        size={"Medium"}
                                        className="icon-dark"
                                        onClick={toggle}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
            <Modal
                isShown={isShown}
                onClose={toggle}
                modalSize={"sm"}
                title={"Delete row..."}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">
                                Are you sure you want to delete this risk assessment?
                            </p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <div className="row mt-3 ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={deleteRiskAssessmentRow}
                            colour={ButtonColourOptions.Yellow}
                            className="mr-3"
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

//ToDo: Stats

export default RiskAssessmentTableRow;
