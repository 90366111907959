import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import EditCategoryForm from "./Components/EditCategoryForm";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import {getCategory} from "../../../store/category/actions/CategoryActions";
import {useParams} from "react-router-dom";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
const ServiceWrapper = WithServiceState(EditCategoryForm);

const EditCategory = () => {
    const dispatch = useDispatch();
    const categoryStore = useSelector((state: RootStore) => state.category);
    const params: any = useParams();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditList.name,
                path: routeNames.auditList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
        dispatch(getCategory(+params.id));
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Edit Category" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...categoryStore}
                />
            </div>
        </React.Fragment>
    );
};

export default EditCategory;
