import React, {ReactNode, useContext} from "react";
import {HiMenuAlt2} from "react-icons/hi";
import TopBarTitle from "./TopBarTitle";
import {NavBarContext} from "../Sidebar/navContext";
import PageTitleTag from "../PageTitleTag";
import TopBarTabs from "./TopBarTabs";

export interface TopBarProps {
    titleText: string;
    leftElem?: ReactNode;
    middleElem?: ReactNode;
    rightElem?: ReactNode;
}

export default function TopBar(props: TopBarProps) {
    const navContext = useContext(NavBarContext);
    const title = props.leftElem ? props.leftElem : <TopBarTitle title={props.titleText} />;
    const rightElem = props.rightElem ?? <TopBarTabs />;

    return (
        <section className="top-bar">
            <PageTitleTag title={props.titleText} />

            {/* LEFT AREA */}
            <div className="left">
                <div className="hamburger-wrapper">
                    <button
                        type="button"
                        onClick={() => {
                            navContext?.setShow((prev) => !prev);
                        }}
                    >
                        <HiMenuAlt2 size={24} />
                    </button>
                </div>

                {title}
            </div>

            {/* MIDDLE AREA */}
            <div className="middle">{props.middleElem}</div>

            {/* RIGHT AREA */}
            <div className="right">{rightElem}</div>
        </section>
    );
}
