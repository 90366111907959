import React, {useEffect} from "react";
import {Venue} from "../../../../api/rm";
import {useDispatch} from "react-redux";

import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {nullifyVenueStore, saveVenue, setVenue} from "../../../../store/venue/actions/VenueActions";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import FormActionContainer from "../../../Form/FormActionContainer";
import {DebounceInput} from "react-debounce-input";

const EditVenueForm = (props: Venue) => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        return function () {
            dispatch(nullifyVenueStore());
        };
    }, []);

    const onVenueNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setVenue({...props, name: event.target.value}));
    };

    const saveVenueToService = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveVenue(props));

        if (!success) return;

        backToVenueList();
    };

    const backToVenueList = () => {
        history.replace(routeNames.venueList.path);
    };
    return (
        <React.Fragment>
            <div className="fadeIn mt-5 pt-3 mb-5">
                <FormHeader headerName={"Venue Form"} />
                <FormRow rowName={"Venue Name"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className="input-fields"
                        value={props.name}
                        onChange={onVenueNameChanged}
                        placeholder="Enter venue name..."
                        type={"text"}
                    />
                </FormRow>
                <div className="shens">
                    <FormActionContainer>
                        <MCButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={saveVenueToService}
                            colour={ButtonColourOptions.Yellow}
                        />
                        <MCButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={backToVenueList}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </FormActionContainer>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditVenueForm;
