import {Dispatch} from "redux";
import {AUDIT_STORE, AuditDispatchTypes} from "./AuditActionTypes";
import store, {RootStore} from "../../Store";
import {getAllCategories} from "../../categoryList/actions/CategoryListActions";
import {getAllVenues} from "../../venueList/actions/VenueListActions";
import {Audit, AuditType} from "../../../api/rm";
import {UserData} from "../../../api/staff";
import moment from "moment";
import {
    AuditForm,
    calculateScoreForAudit,
    SetAuditFormAction,
    validateAudit
} from "../helpers/auditHelpers";
import {generateBlankFireSafetyAudit} from "../helpers/FireSafety/fireSafetyAuditHelpers";
import _ from "lodash";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import {capitalizeFirstLetter, decapitalizeFirstLetter} from "../../../utils/textUtils";
import {showErrorToast} from "../../../utils/toastUtils";
import {generateBlankPremisesDepotAudit} from "../helpers/Premises/premisesDepotAudit";
import {generateBlankPremisesTrainingAndStorageAudit} from "../helpers/Premises/premisesTrainingAndStorageAudit";
import {generateBlankHeadOfficeAudit} from "../helpers/Premises/premisesHeadOfficeAudit";
import {
    createBlankRiskAssessmentRow,
    generateBlankRiskAssessment,
    RiskAssessmentMatrixRow,
    updateRiskAssessmentRowVersion,
    validateRiskAssessmentMatrix
} from "../helpers/RiskAssessment/riskAssessmentMatrixHelpers";
import {getAllMedicareStaffMembers} from "../../staffList/actions/StaffListActions";
import {getDataFromServiceWithData, postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {generateBlankWeeklyFireSafetyAudit} from "../helpers/FireSafety/weeklyFireSafetyAudit";
import {generateBlankMonthlyFireSafetyAudit} from "../helpers/FireSafety/monthlyFireSafetyAudit";
import {generateBlankBiAnnualFireSafetyAudit} from "../helpers/FireSafety/biAnnualFireSafetyAudit";
import {generateBlankAnnualFireSafetyAudit} from "../helpers/FireSafety/annualFireSafetyAudit";
import {generateBlankFiveYearFireSafetyAudit} from "../helpers/FireSafety/fiveYearFireSafetyAudit";

export const nullifyAuditStore = () => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createBlankAudit = (user: UserData) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            dispatch({
                type: AUDIT_STORE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllMedicareStaffMembers());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllCategories());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllVenues());

            dispatch({
                type: AUDIT_STORE.SUCCESS,
                error: null,
                loading: false,
                data: generateNewAudit(user) //ToDo: Remove after testing
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const getAuditById = (id: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            dispatch({
                type: AUDIT_STORE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllMedicareStaffMembers());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllCategories());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getAllVenues());

            const audit = await getDataFromServiceWithData(
                AUDIT_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().getAudit(id),
                statusCodeCallback
            );
            if (!audit) {
                showErrorToast("Could not get specified audit");
                return;
            }
            const updatedAudit: Audit = {
                ...audit,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                type: capitalizeFirstLetter(audit.type)
            };
            dispatch({
                type: AUDIT_STORE.SUCCESS,
                error: null,
                loading: false,
                data: updatedAudit
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const saveAuditToService = (audit: Audit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        try {
            const valid = validateAudit(audit);
            if (!valid) return;
            const user = state().auth.data;
            if (!user) return;

            let auditForm: AuditForm = JSON.parse(audit.payload);

            if (
                audit.type === AuditType.RiskAssessmentMatrix &&
                auditForm.riskAssessmentMatrixAudit
            ) {
                const isValid = validateRiskAssessmentMatrix(auditForm.riskAssessmentMatrixAudit);

                if (!isValid) return;
                auditForm = updateRiskAssessmentRowVersion(auditForm);
            }

            const updatedAudit: Audit = {
                ...audit,
                payload: JSON.stringify(auditForm),
                complianceScore: calculateScoreForAudit(audit),
                authorName: `${user.firstName} ${user.lastName}`,
                authorId: user.username,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                type: decapitalizeFirstLetter(audit.type),
                dateCreated: moment().unix()
            };

            return await postDataToServiceWithRedux(
                AUDIT_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().saveAudit(updatedAudit),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                loading: false,
                error: e
            });

            return false;
        }
    };
};
/** Updates Audit. */
export const setAudit = (audit: Audit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: audit
        });
    };
};

/** Updates Audit Type. */
export const setAuditType = (auditType: AuditType) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);

        //Add the specified audit to the audit form
        const updatedAuditForm: AuditForm = addSpecifiedAuditToAuditForm(auditType, auditForm);

        // Update the audit type
        const updatedAudit: Audit = {
            ...audit,
            type: auditType
        };
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: stringifyPayload(updatedAudit, updatedAuditForm)
        });
    };
};

export const setAuditForm = (action: SetAuditFormAction) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);

        const path: string = [...action.nestingList, action.key].join(".");
        _.set(auditForm, path, action.updatedQuestion);

        //Add the specified audit to the audit form
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

export const addRowToRiskAssessmentMatrix = () => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);

        if (!auditForm.riskAssessmentMatrixAudit) return;

        auditForm.riskAssessmentMatrixAudit.rows.push(createBlankRiskAssessmentRow());

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

export const setRiskAssessmentMatrixRow = (row: RiskAssessmentMatrixRow) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        const audit = state().audit.data;
        if (!audit) return;
        const auditForm: AuditForm = JSON.parse(audit.payload);

        if (!auditForm.riskAssessmentMatrixAudit) return;
        const index = auditForm.riskAssessmentMatrixAudit.rows.findIndex(
            (item: RiskAssessmentMatrixRow) => item.id === row.id
        );
        if (index < 0) return;
        auditForm.riskAssessmentMatrixAudit.rows[index] = row;

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

export const removeRowFromRiskAssessmentMatrix = (rowId: string) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        const audit = state().audit.data;
        if (!audit) return;

        const auditForm: AuditForm = JSON.parse(audit.payload);

        if (!auditForm.riskAssessmentMatrixAudit) return;
        const index = auditForm.riskAssessmentMatrixAudit.rows.findIndex(
            (item: RiskAssessmentMatrixRow) => item.id === rowId
        );
        auditForm.riskAssessmentMatrixAudit.rows.splice(index, 1);

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: stringifyPayload(audit, auditForm)
        });
    };
};

export function generateAuditForm(): AuditForm {
    return {
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Takes the audit form and stringifies the payload */
function stringifyPayload(audit: Audit, auditForm: AuditForm): Audit {
    return {
        ...audit,
        payload: JSON.stringify(auditForm)
    };
}

/** Adds specified audit to the form */
function addSpecifiedAuditToAuditForm(auditType: AuditType, auditForm: AuditForm): AuditForm {
    switch (auditType) {
        case AuditType.AuditPremisesDepot:
            return addPremisesDepotFormToAuditForm(auditForm);
        case AuditType.AuditPremisesTrainingArea:
            return addPremisesTrainingAndPremisesFormToAuditForm(auditForm);
        case AuditType.AuditPremisesHeadOffice:
            return addPremisesMainOfficeFormToAuditForm(auditForm);
        case AuditType.RiskAssessmentMatrix:
            return addRiskAssessmentFormToAuditForm(auditForm);
        case AuditType.AuditFireSafety:
            return addFireSafetyFormToAuditForm(auditForm);
        case AuditType.AuditWeeklyFireSafety:
            return addWeeklyFireSafetyFormToAuditForm(auditForm);
        case AuditType.AuditMonthlyFireSafety:
            return addMonthlyFireSafetyFormToAuditForm(auditForm);
        case AuditType.AuditBiAnnualFireSafety:
            return addBiAnnualFireSafetyFormToAuditForm(auditForm);
        case AuditType.AuditAnnualFireSafety:
            return addAnnualFireSafetyFormToAuditForm(auditForm);
        case AuditType.AuditFiveYearlyFireSafety:
            return addFiveYearFireSafetyFormToAuditForm(auditForm);
        default:
            return auditForm;
    }
}

/** Add the risk assessment audit to the audit form whilst nullifying the rest of the audits */
function addRiskAssessmentFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: generateBlankRiskAssessment()
    };
}

/** Add the fire safety audit to the audit form whilst nullifying the rest of the audits */
function addFireSafetyFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: generateBlankFireSafetyAudit(),
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the weekly fire safety audit to the audit form whilst nullifying the rest of the audits */
function addWeeklyFireSafetyFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: generateBlankWeeklyFireSafetyAudit(),
        monthlyFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the monthly fire safety audit to the audit form whilst nullifying the rest of the audits */
function addMonthlyFireSafetyFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: generateBlankMonthlyFireSafetyAudit(),
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the Bi Annual fire safety audit to the audit form whilst nullifying the rest of the audits */
function addBiAnnualFireSafetyFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: generateBlankBiAnnualFireSafetyAudit(),
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the Annual fire safety audit to the audit form whilst nullifying the rest of the audits */
function addAnnualFireSafetyFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: generateBlankAnnualFireSafetyAudit(),
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the Annual fire safety audit to the audit form whilst nullifying the rest of the audits */
function addFiveYearFireSafetyFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: generateBlankFiveYearFireSafetyAudit(),
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the premises training and storage audit to the audit form whilst nullifying the rest of the audits */
function addPremisesTrainingAndPremisesFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: generateBlankPremisesTrainingAndStorageAudit(),
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the premises depot audit to the audit form whilst nullifying the rest of the audits */
function addPremisesDepotFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: generateBlankPremisesDepotAudit(),
        premisesHeadOfficeAudit: undefined,
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

/** Add the premises depot audit to the audit form whilst nullifying the rest of the audits */
function addPremisesMainOfficeFormToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        fireSafetyAudit: undefined,
        weeklyFireSafetyAudit: undefined,
        monthlyFireSafetyAudit: undefined,
        biAnnualFireSafetyAudit: undefined,
        annualFireSafetyAudit: undefined,
        fiveYearFireSafetyAudit: undefined,
        premisesDepotAudit: undefined,
        premisesHeadOfficeAudit: generateBlankHeadOfficeAudit(),
        premisesTrainingStorageAreaAudit: undefined,
        riskAssessmentMatrixAudit: undefined
    };
}

function generateNewAudit(user: UserData): Audit {
    return {
        id: 0,
        categoryId: 0,
        venueId: 0,
        dateCreated: moment().unix(),
        type: AuditType.None,
        complianceScore: 100,
        authorId: user.username,
        authorName: `${user.firstName} ${user.lastName}`,
        payload: JSON.stringify(generateAuditForm())
    };
}
