import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import TopBarLink from "./TopBarLink";

export default function TopBarTabs() {
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];

    return (
        <>
            {navItems.map((item) => {
                if (item.path === undefined) return undefined;
                return <TopBarLink title={item.name} to={item.path} key={item.name} />;
            })}
        </>
    );
}
