import {FireSafetyAudit} from "./FireSafety/fireSafetyAuditHelpers";
import {Audit, AuditType} from "../../../api/rm";
import {showErrorToast} from "../../../utils/toastUtils";
import {PremisesDepotAudit} from "./Premises/premisesDepotAudit";
import {PremisesTrainingAndStorageAudit} from "./Premises/premisesTrainingAndStorageAudit";
import {clamp} from "../../../utils/mathUtils";
import {PremisesHeadOfficeAudit} from "./Premises/premisesHeadOfficeAudit";
import {RiskAssessmentMatrix} from "./RiskAssessment/riskAssessmentMatrixHelpers";
import {WeeklyFireSafetyAudit} from "./FireSafety/weeklyFireSafetyAudit";
import {MonthlyFireSafetyAudit} from "./FireSafety/monthlyFireSafetyAudit";
import {BiAnnualFireSafetyAudit} from "./FireSafety/biAnnualFireSafetyAudit";
import {AnnualFireSafetyAudit} from "./FireSafety/annualFireSafetyAudit";
import {FiveYearFireSafetyAudit} from "./FireSafety/fiveYearFireSafetyAudit";

// eslint-disable-next-line no-shadow
export enum Answer {
    Yes = "yes",
    No = "no",
    NotApplicable = "notApplicable"
}
// eslint-disable-next-line no-shadow
export enum YesNo {
    Yes = "yes",
    No = "no"
}

export interface QuestionWithAnswer {
    answer: Answer;
    question: string;
    scoreChange: number;
    indentQuestion?: boolean;
}

export interface QuestionWithAnswerAndComments extends QuestionWithAnswer {
    comments: string;
}

export interface QuestionWithAnswerAndCommentsAndDate extends QuestionWithAnswerAndComments {
    date: number;
    dateLabel?: string;
}

export interface SectionDetails {
    sectionName: string;
    details: string;
}

export interface AuditForm {
    riskAssessmentMatrixAudit?: RiskAssessmentMatrix;
    premisesDepotAudit?: PremisesDepotAudit;
    premisesHeadOfficeAudit?: PremisesHeadOfficeAudit;
    premisesTrainingStorageAreaAudit?: PremisesTrainingAndStorageAudit;
    fireSafetyAudit?: FireSafetyAudit;
    weeklyFireSafetyAudit?: WeeklyFireSafetyAudit;
    monthlyFireSafetyAudit?: MonthlyFireSafetyAudit;
    biAnnualFireSafetyAudit?: BiAnnualFireSafetyAudit;
    annualFireSafetyAudit?: AnnualFireSafetyAudit;
    fiveYearFireSafetyAudit?: FiveYearFireSafetyAudit;
}

export interface SetAuditFormAction {
    nestingList: string[];
    key: string;
    updatedQuestion:
        | QuestionWithAnswer
        | QuestionWithAnswerAndComments
        | QuestionWithAnswerAndCommentsAndDate
        | SectionDetails;
}

/** Generates a basic question with answer */
export function newQuestionWithAnswer(
    question: string,
    answer?: Answer,
    scoreChange?: number,
    indentQuestion?: boolean
): QuestionWithAnswer {
    return {
        question,
        answer: answer ? answer : Answer.NotApplicable,
        scoreChange: scoreChange ? scoreChange : 0,
        indentQuestion
    };
}

export function newQuestionWithAnswerAndComments(
    question: string,
    answer?: Answer,
    scoreChange?: number,
    indentQuestion?: boolean
): QuestionWithAnswerAndComments {
    return {
        question,
        scoreChange: scoreChange ? scoreChange : 0,
        comments: "",
        answer: answer ? answer : Answer.NotApplicable,
        indentQuestion
    };
}

export function newQuestionWithAnswerAndCommentsAndDate(
    question: string,
    date: number,
    answer?: Answer,
    dateLabel?: string,
    scoreChange?: number
): QuestionWithAnswerAndCommentsAndDate {
    return {
        question,
        date,
        scoreChange: scoreChange ? scoreChange : 0,
        comments: "",
        answer: answer ? answer : Answer.NotApplicable,
        dateLabel
    };
}

export function newSectionDetailItem(sectionName: string): SectionDetails {
    return {
        sectionName,
        details: ""
    };
}

export function validateAudit(audit: Audit): boolean {
    let isValid = true;
    if (audit.categoryId === 0) {
        isValid = false;
        showErrorToast("A category must be selected");
    }
    if (audit.venueId === 0) {
        isValid = false;
        showErrorToast("A venue must be selected");
    }

    if (audit.type === AuditType.None) {
        isValid = false;
        showErrorToast("An audit type must be selected");
    }

    if (audit.payload === "{}") {
        isValid = false;
        showErrorToast("Audit payload was not created, contact system administrator!");
    }

    return isValid;
}

export function calculateScoreForAudit(audit: Audit): number | undefined {
    switch (audit.type) {
        case AuditType.AuditPremisesDepot:
        case AuditType.AuditPremisesHeadOffice:
        case AuditType.AuditPremisesTrainingArea:
            return getScoreForAuditForm(audit);
        case AuditType.RiskAssessmentMatrix:
        case AuditType.AuditFireSafety:
        case AuditType.None:
            return undefined;
    }
}

function getScoreForAuditForm(audit: Audit) {
    const form: PremisesTrainingAndStorageAudit | PremisesDepotAudit | PremisesHeadOfficeAudit =
        JSON.parse(audit.payload);
    const auditKey = Object.keys(form).join(".");

    if (!form) return -1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const auditScore = getScoreForQuestions(form[auditKey]);
    const score = 100 - auditScore;
    return clamp(score, 0, 100);
}

/** Scans through the questions and will return the amount the total score needs to be subtracted by */
export function getScoreForQuestions<T>(audit: T): number {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sectionKeys = Object.keys(audit);
    let score = 0;
    for (const sectionKey of sectionKeys) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const questionKeys = Object.keys(audit[sectionKey]);

        for (const questionKey of questionKeys) {
            const question:
                | QuestionWithAnswer
                | QuestionWithAnswerAndComments
                | QuestionWithAnswerAndCommentsAndDate
                | SectionDetails
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                | string = audit[sectionKey][questionKey];

            // If the question is undefined, we want to loop over it.
            if (!question) {
                continue;
            }

            //Ignore if the question is a string
            if (typeof question === "string") {
                continue;
            }

            if ("details" in question) {
                continue;
            }

            // Get the increment value of the score change if the answer is no, otherwise, we don't increment the score.
            const inc = question.answer === Answer.No ? question.scoreChange : 0;

            score += inc;
        }
    }
    return score;
}
