import React from "react";
import FormRow from "../Form/FormRow";
import {getFlooredPercentage} from "../../utils/mathUtils";

const BarChart = (props: BarChartProps) => {
    const getBarWidth = () => {
        return getFlooredPercentage(props.currentValue, props.maxValue);
    };
    return (
        <React.Fragment>
            <FormRow rowName={props.label} columnDetailClassName={"pl-0 pr-0"}>
                <div className="bar-base">
                    <div
                        className="bar-value"
                        style={{width: `${getBarWidth()}%`, background: "#3d5368"}}
                    />
                </div>
            </FormRow>
        </React.Fragment>
    );
};

export default BarChart;

interface BarChartProps {
    maxValue: number;
    currentValue: number;
    label: string;
    barColour: string;
    tooltipText: string;
}
