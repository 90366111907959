import React, {useEffect} from "react";
import {Venue} from "../../../../api/rm";
import {useDispatch} from "react-redux";
import {nullifyVenueListStore} from "../../../../store/venueList/actions/VenueListActions";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {PulseTable} from "pulse_table";
import {toArray} from "../../../../utils/sortingUtils";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import TableItemAction from "../../../Table/TableItemAction";
import {IconType} from "../../../Icon/Icon";

const VenueListTable = (props: Venue[]) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return function () {
            dispatch(nullifyVenueListStore());
        };
    }, []);

    const editVenue = (row: TableRow) => {
        const path = `${routeNames.venueEdit.path}/${row.actions}`;
        history.push(path);
    };

    const addVenue = () => {
        history.push(routeNames.venueAdd.path);
    };

    return (
        <div className="shens">
            <FormActionContainer>
                <div className="col pr-0 pl-0 d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Venue"}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                        onClick={addVenue}
                    />
                </div>
            </FormActionContainer>
            <PulseTable
                items={toTableRow(toArray(props))}
                headers={{
                    name: "Name",
                    actions: "Actions"
                }}
                noItemsSection={<NoItemsMessage message={"There are no categories that exist"} />}
                customRenderers={{
                    actions: (item: TableRow) => {
                        return (
                            <React.Fragment>
                                <TableItemAction
                                    tooltipText={`Edit Category (${item.name})`}
                                    icon={IconType.Edit}
                                    onClick={() => editVenue(item)}
                                />
                            </React.Fragment>
                        );
                    }
                }}
            />
        </div>
    );
};

export default VenueListTable;
interface TableRow {
    name: string;
    actions: number;
}

function toTableRow(props: Venue[]): TableRow[] {
    return props.map((item) => {
        return {
            name: item.name,
            actions: item.id
        };
    });
}
