import {VENUE_STORE, VenueDispatchTypes} from "./VenueActionTypes";
import {Dispatch} from "redux";
import {Venue} from "../../../api/rm";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import {validateCategoryVenueForm} from "../../../utils/categoryVenueUtils";
import {getDataFromServiceWithRedux, postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the venue list store. */
export const nullifyVenueStore = () => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        dispatch({
            type: VENUE_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createNewVenue = () => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        dispatch({
            type: VENUE_STORE.SUCCESS,
            error: null,
            loading: false,
            data: getEmptyVenue()
        });
    };
};

export const setVenue = (venue: Venue) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        dispatch({
            type: VENUE_STORE.SUCCESS,
            error: null,
            loading: false,
            data: venue
        });
    };
};

export const fetchVenue = (id: number) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                VENUE_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().getVenue(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const saveVenue = (venue: Venue) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        try {
            const {valid} = validateCategoryVenueForm(venue, "Venue");
            if (!valid) return false;

            return await postDataToServiceWithRedux(
                VENUE_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().saveVenue(venue),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_STORE.ERROR,
                error: e,
                loading: false
            });
            return false;
        }
    };
};

export function getEmptyVenue(): Venue {
    return {
        id: 0,
        name: ""
    };
}

export function getVenueName(
    venueId: number,
    venueList: Venue[] | undefined | null
): string | undefined {
    if (!venueList) return;
    const index = venueList?.findIndex((venue: Venue) => venue.id === venueId);

    return index < 0 ? "Venue deleted" : venueList[index].name;
}
