import {Dispatch} from "redux";
import {AUDIT_LIST_PAGED_STORE, AuditListPagedDispatchTypes} from "./AuditListPagedActionTypes";
import {AuditListEntry, AuditListRequest, AuditListResponse, AuditType} from "../../../api/rm";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import RiskManagementApiModel from "../../apiModel/RiskManagementApiModel";
import store from "../../Store";
import {getAllCategories} from "../../categoryList/actions/CategoryListActions";
import {getAllVenues} from "../../venueList/actions/VenueListActions";
import {getRandomNumber} from "../../../utils/mathUtils";
import {nanoid} from "nanoid";
import moment from "moment";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyAuditListPagedStore = () => {
    return async (dispatch: Dispatch<AuditListPagedDispatchTypes>) => {
        dispatch({
            type: AUDIT_LIST_PAGED_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const fetchPagedAuditList = (request: AuditListRequest) => {
    return async (dispatch: Dispatch<AuditListPagedDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                AUDIT_LIST_PAGED_STORE,
                dispatch,
                () => RiskManagementApiModel.getRmApi().getAuditList(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_LIST_PAGED_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const fetchPagedAuditListWithFilters = () => {
    return async (dispatch: Dispatch<AuditListPagedDispatchTypes>) => {
        try {
            dispatch({
                type: AUDIT_LIST_PAGED_STORE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Promise.all([store.dispatch(getAllCategories()), store.dispatch(getAllVenues())]);

            dispatch({
                type: AUDIT_LIST_PAGED_STORE.SUCCESS,
                error: null,
                loading: false,
                data: getBlankAuditResponse()
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_LIST_PAGED_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

function getBlankAuditResponse(): AuditListResponse {
    return {
        results: [],
        numPerPage: 0,
        pageNum: 0,
        totalEntries: 0
    };
}

/** DEVELOPMENT PURPOSES */
function generateAuditListResponse(): AuditListResponse {
    const results = generateAuditListEntries(20);
    return {
        results,
        numPerPage: 50,
        pageNum: 0,
        totalEntries: 20
    };
}

function generateAuditListEntries(amountToGenerate: number): AuditListEntry[] {
    const results: AuditListEntry[] = [];
    for (let i = 0; i < amountToGenerate; ++i) {
        const categoryId = getRandomNumber(0, 4);
        const venueId = getRandomNumber(0, 1);
        const auditId = getRandomNumber(0, 4);

        results.push({
            id: i,
            authorId: nanoid(),
            authorName: names[getRandomNumber(0, 9)],
            categoryId: categoryId + 1,
            categoryName: categoryNames[categoryId],
            venueId: venueId + 1,
            venueName: venueNames[venueId],
            dateCreated: moment().unix(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: auditTypes()[auditId]
        });
    }
    return results;
}

const categoryNames: string[] = ["Corporate", "Events", "Medicare Premises", "NHS", "Racing"];
const venueNames: string[] = ["Coventry Depot", "Chelmsford Depot"];
const names: string[] = [
    "Imaad Peterson",
    "Pierre Lovell",
    "Anton Hibbert",
    "Kiya William",
    "Lyra Horton",
    "Jed Sloan",
    "Kaitlan Plant",
    "Edie Rosas",
    "Indi Reynolds",
    "Ayub George"
];

const auditTypes = () => Object.keys(AuditType);
