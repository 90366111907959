import React from "react";
import {Audit, AuditType, HistoricAudit} from "../../../../api/rm";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {capitalizeFirstLetter, getUiFriendlyText} from "../../../../utils/textUtils";
import AuditFormContainer from "../../Audit/Components/Forms/General/AuditFormContainer";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import {getCategoryName} from "../../../../store/category/actions/CategoryActions";
import {getVenueName} from "../../../../store/venue/actions/VenueActions";

const HistoricAuditForm = (props: HistoricAudit) => {
    const categoryList = useSelector((state: RootStore) => state.categoryList.data);
    const venueList = useSelector((state: RootStore) => state.venueList.data);
    const history = useHistory();

    const renderOptionButtons = (key: string, audit: Audit): JSX.Element | undefined => {
        switch (capitalizeFirstLetter(key)) {
            case AuditType.AuditFireSafety:
            case AuditType.None:
                return undefined;
            default:
                return (
                    <div className="col-sm-3 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={getUiFriendlyText(key)}
                            className="w-100"
                            onClick={() => console.log("")}
                            colour={
                                key === capitalizeFirstLetter(audit.type)
                                    ? ButtonColourOptions.Yellow
                                    : ButtonColourOptions.DarkBlue
                            }
                            disabled={true}
                        />
                    </div>
                );
        }
    };

    const goBackToHistoricAuditList = () => {
        const path = `${routeNames.auditHistoricList.path}/${props.originalId}`;
        history.replace(path);
    };
    return (
        <React.Fragment>
            <div className="page-container">
                <div className="fadeIn mt-5 pt-3 mb-5">
                    <FormHeader headerName={"Audit Details"} />
                    <FormRow rowName="Author">
                        <p className="mb-0">{props.authorName}</p>
                    </FormRow>
                    <FormRow rowName="Audit Date">
                        <p className="mb-0">{formatUnixToDDMMYYYY(props.dateCreated)}</p>
                    </FormRow>
                    {props.complianceScore && (
                        <FormRow rowName="Score">
                            <p className="mb-0">{props.complianceScore}</p>
                        </FormRow>
                    )}
                    <FormRow rowName="Category">
                        {getCategoryName(props.categoryId, categoryList)}
                    </FormRow>
                    <FormRow rowName="Venue">{getVenueName(props.venueId, venueList)}</FormRow>
                    <FormHeader headerName={"Audit Type"} />
                    <FormActionContainer>
                        <React.Fragment>
                            {Object.keys(AuditType).map((item: string) => {
                                return renderOptionButtons(item, props);
                            })}
                        </React.Fragment>
                    </FormActionContainer>
                    <AuditFormContainer {...JSON.parse(props.payload)} />
                    <div className="shens">
                        <FormActionContainer withColumn>
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue="Back"
                                onClick={goBackToHistoricAuditList}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </FormActionContainer>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default HistoricAuditForm;
