import React, {useEffect, useState} from "react";
import {AuditListEntry, AuditListRequest, AuditListResponse} from "../../../../api/rm";
import {
    fetchPagedAuditList,
    nullifyAuditListPagedStore
} from "../../../../store/auditListPaged/actions/AuditListPagedActions";
import FilterContainer from "../../../Filters/FilterContainer";
import AuditListPagedFilters from "./AuditListPagedFilters";
import {useDispatch} from "react-redux";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {formatUnixToDateTime} from "../../../../utils/momentUtils";
import {PulseTableWithServerPagination} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {routeNames} from "../../../Navigation/routeNames";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";

const AuditListTable = (props: AuditListResponse) => {
    const dispatch = useDispatch();
    const [auditListRequest, setAuditListRequest] = useState<AuditListRequest>();
    const history = useHistory();
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        return () => {
            dispatch(nullifyAuditListPagedStore());
        };
    }, []);

    const onRequestChanged = (request: AuditListRequest) => {
        if (!auditListRequest) {
            setAuditListRequest(request);
            fetchPagedAudits(request);
            return;
        }
        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(request) === JSON.stringify(auditListRequest)) return;

        fetchPagedAudits(request);
        setAuditListRequest(request);
    };

    // Fetches Audits
    const fetchPagedAudits = (request: AuditListRequest) => {
        dispatch(fetchPagedAuditList(request));
    };

    const newAudit = () => {
        history.push({
            pathname: routeNames.auditAdd.path,
            search: searchQueries
        });
    };

    const editAudit = (item: TableRow) => {
        const path = `${routeNames.auditEdit.path}/${item.actions.id}`;
        history.push(path);
    };

    const viewAuditHistory = (item: TableRow) => {
        const path = `${routeNames.auditHistoricList.path}/${item.actions.id}`;
        history.push(path);
    };

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <div className="d-block overflow-auto w-100">
                    <AuditListPagedFilters onRequestChanged={onRequestChanged} />
                </div>
            </FilterContainer>
            <div className="shens">
                <FormActionContainer>
                    <div className="col d-flex justify-content-end pl-0 pr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"New Audit"}
                            colour={ButtonColourOptions.Yellow}
                            onClick={newAudit}
                            roundedCorner
                        />
                    </div>
                </FormActionContainer>
            </div>
            <PulseTableWithServerPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                totalResults={props.totalEntries}
                onPageChanged={(newPageNum) => {
                    if (!auditListRequest) return;
                    fetchPagedAudits({
                        ...auditListRequest,
                        pageNum: newPageNum
                    });
                }}
                items={toTableRow(props)}
                headers={{
                    auditType: "Audit Type",
                    venue: "Venue",
                    dateCreated: "Date Created",
                    author: "Author",
                    category: "Category",
                    actions: "Actions"
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no audits matching these filters"} />
                }
                customRenderers={{
                    auditType: (item: TableRow) => (
                        <div className="link" onClick={() => editAudit(item)}>
                            {item.auditType}
                        </div>
                    ),
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            <div className="row ml-0 mr-0">
                                <div className="icon-tooltip-wrapper-md mr-3">
                                    <Tooltip
                                        tooltipText={"View Audit History"}
                                        size={"md"}
                                        place={"left"}
                                    >
                                        <Icon
                                            rootElement={"Div"}
                                            icon={IconType.ViewHistory}
                                            size={"Medium"}
                                            className="icon-dark"
                                            onClick={() => viewAuditHistory(item)}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }}
            />
        </React.Fragment>
    );
};

export default AuditListTable;

interface TableRow {
    auditType: string;
    venue: string;
    dateCreated: string;
    author: string;
    category: string;
    actions: AuditListEntry;
}

function toTableRow(props: AuditListResponse): TableRow[] {
    return props.results.map((item) => {
        return {
            auditType: getUiFriendlyText(item.type),
            venue: item.venueName,
            dateCreated: formatUnixToDateTime(item.dateCreated),
            author: item.authorName,
            category: item.categoryName,
            actions: item
        };
    });
}
