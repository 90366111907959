import React from "react";
import PrintableTableHead from "../PrintableTableHead";
import PrintableTableRow from "../PrintableTableRow";
import PrintableTH from "../PrintableTH";
import MatrixRatingTableHeader from "./MatrixRatingTableHeader";

const PrintableRiskAssessmentTableHeader = () => {
    return (
        <PrintableTableHead>
            <PrintableTableRow rowType={"headerRow"}>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Risk Description
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Cause/Source of Identified Risk
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Date Created
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Last Reviewed
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Time Scale For Review
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Review Date
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Risk Category
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Risk Owner
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    <MatrixRatingTableHeader headerName={"Initial Rating"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Existing Controls
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    <MatrixRatingTableHeader headerName={"Current Rating"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Further Actions
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    <MatrixRatingTableHeader headerName={"Target Rating"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                    Additional Comments
                </PrintableTH>
            </PrintableTableRow>
        </PrintableTableHead>
    );
};

export default PrintableRiskAssessmentTableHeader;
