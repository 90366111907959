import React, {useEffect} from "react";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useQuery} from "../../Hooks/useQuery";
import {getHistoricAuditForLiveAudit} from "../../../store/historicAudit/actions/HistoricAuditActions";
import {showErrorToast} from "../../../utils/toastUtils";
import {RootStore} from "../../../store/Store";
import HistoricAuditForm from "./Components/HistoricAuditForm";
import {WithServiceState} from "store-fetch-wrappers";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";
const ServiceWrapper = WithServiceState(HistoricAuditForm);

const ViewHistoricAudit = () => {
    const dispatch = useDispatch();
    const {fullPath} = usePageUrl();
    const params: any = useParams();
    const query = useQuery();
    const historicAuditStore = useSelector((state: RootStore) => state.historicAudit);
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.overview.name,
                path: routeNames.overview.path
            },
            {
                name: routeNames.categoryList.name,
                path: routeNames.categoryList.path
            },
            {
                name: routeNames.venueList.name,
                path: routeNames.venueList.path
            },
            {
                name: routeNames.auditHistoricList.name,
                path: getAuditHistoricListPath(+params.auditId)
            },
            {
                name: routeNames.auditHistoricView.name,
                path: fullPath
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
        const historicId = query.get("historicId");
        if (!historicId) {
            showErrorToast(
                "Could not get historic audit as historic Id was not included in request. Contact system administrator"
            );
            return;
        }
        dispatch(getHistoricAuditForLiveAudit(+params.auditId, +historicId));
    }, []);
    return (
        <React.Fragment>
            <TopBar titleText="Historic Audit" />
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...historicAuditStore}
            />
        </React.Fragment>
    );
};

export default ViewHistoricAudit;

function getAuditHistoricListPath(auditId: number) {
    return `${routeNames.auditHistoricList.path}/${auditId}`;
}
