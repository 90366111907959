import {Link} from "react-router-dom";
import React, {ReactNode} from "react";

export interface PageHeadingLinkProps {
    className?: string;
    icon?: ReactNode;
    title: string;
    to: string;
}

export default function TopBarLink(props: PageHeadingLinkProps) {
    const className = props.className ?? "";

    return (
        <Link aria-label={props.title} className={`top-bar top-bar-btn ${className}`} to={props.to}>
            {props.icon ? <div className="top-bar top-bar-btn-icon">{props.icon}</div> : undefined}
            <div className="top-bar top-bar-btn-content">{props.title}</div>
        </Link>
    );
}
